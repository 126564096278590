export const APP_CONSTANT = {
    JWT_TOKEN_KEY: 'prime_jwt_auth',
    BEFORE_IMPERSONATE_JWT_KEY: 'before_impersonate_jwt',
    LOGGED_USER: 'logged_user',
    ERROR_PATH: '/error/404',
    USER_LOCALE_KEY: 'user_locale',
    USER_LANGUAGE_KEY: 'language',
    PUSH_NOTIFICATION_PUBLIC_KEY: 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo',
};
export const RE_DIRECT_TO_RULE = {
    navigationCommands: [APP_CONSTANT.ERROR_PATH],
    navigationExtras: {
        skipLocationChange: true
    }
};
export const MAT_DIALOG_OPTIONS = {
    hasBackdrop: true,
    panelClass: 'app-mat-dialog-container__wrapper',
    height: 'auto',
    width: '800px',
    autoFocus: false,
    maxHeight: '90vh',
    maxWidth: '900px'
};
export const APP_ROLES = {
    SUPERADMIN: 'SUPERADMIN'
};
export const COUNTRIES = {
    752: {
        country: 'Sweden',
        flag: './assets/media/flags/sweden.svg'
    },
    578: {
        country: 'Norway',
        flag: './assets/media/flags/norway.svg'
    },
    840: {
        country: 'England',
        flag: './assets/media/flags/england.svg'
    },
    724: {
        country: 'Spain',
        flag: './assets/media/flags/spain.svg'
    },
    208: {
        country: 'Denmark',
        flag: './assets/media/flags/denmark.svg'
    },
    352: {
        country: 'Iceland',
        flag: './assets/media/flags/iceland.svg'
    },
    246: {
        country: 'Finland',
        flag: './assets/media/flags/finland.svg'
    },
    250: {
        country: 'France',
        flag: './assets/media/flags/france.svg'
    },
    380: {
        country: 'Italy',
        flag: './assets/media/flags/italy.svg'
    },
    276: {
        country: 'Germany',
        flag: './assets/media/flags/germany.svg'
    },
    356: {
        country: 'India',
        flag: './assets/media/flags/india.svg'
    },
    642: {
        country: 'Romania',
        flag: './assets/media/flags/romania.svg'
    },
    428: {
        country: 'Latvia',
        flag: './assets/media/flags/latvia.svg'
    },
    348: {
        country: 'Hungary',
        flag: './assets/media/flags/hungary.svg'
    }
};
export const LOCALES = {
    'sv-se': 'Svenska',
    'nb-no': 'Norsk',
    'en-se': 'English',
    'es-es': 'Español',
    'da-dk': 'Dansk',
    'is-is': 'Íslensku',
    'fi-fi': 'Suomi',
    'fr-fr': 'French',
    'it-it': 'Italian',
    'de-de': 'Deutch'
};
export const ORDER_SITE_IDS = {
    1: 'Bildelsbasen',
    5: 'Varaosahaku',
    90: 'Kalkyler',
    91: 'Kalkyler',
    92: 'Kalkyler',
    93: 'Kalkyler'
};
export const SHIPPING_PROVIDER_IDS = {
    0: 'Open provider',
    1: 'Pick up at location',
    2: 'Courier van',
    3: 'Posten',
    4: 'DHL ServicePoint',
    5: 'Bussgods',
    6: 'Schenker',
    7: 'DPD',
    9: 'DHL',
    10: 'TNT Express',
    11: 'BestXpress',
    12: 'Posti',
    13: 'Matkahuolto - BussiPaketti',
    14: 'Transpoint',
    15: 'Matkahuolto',
    16: 'Kaukokiito',
    17: 'UPS',
    18: 'Colleague only',
    19: 'Bring / Posti (VOH.fi)',
    20: 'Bring',
    21: 'The recipient pays'
};
export const ORER_TIME_ID = {
    1: '1-3 days',
    2: '4-8 days',
    3: '1 week',
    4: 'Not in stock',
    5: 'Demounted when sold'
};
export const PAYMENT_PROVIDER_IDS = {
    1: 'Dibs',
    7: 'Cash On Delivery',
    8: 'Invoice issued by dismantler',
    10: 'Bank payment',
    11: 'Bank payment',
    12: 'PayTrail',
    13: 'Invoice issued by Fortus',
    15: 'Payson',
    17: 'Swish - Företag',
    18: 'Swish - Handel',
    19: 'Nets',
    20: 'Rapyd',
    21: 'Paytrail.v2'
};
export const PART_POSITION_BITS = {
    1: 'Inner',
    2: 'Outer',
    3: 'Right',
    4: 'Left',
    5: 'Front',
    6: 'Back',
    7: 'Upper',
    8: 'Lower',
    9: 'Center'
};
export const ORDER_STATES = {
    1: {
        label: 'label_inquiry',
        color: 'metal',
        icon: 'flaticon-list'
    },
    2: {
        label: 'label_ordered',
        color: 'primary',
        icon: 'flaticon-open-box'
    },
    3: {
        label: 'label_confirmed',
        color: 'success',
        icon: 'flaticon-business'
    },
    4: {
        label: 'label_delivered',
        color: 'dark',
        icon: 'flaticon-truck'
    },
    5: {
        label: 'label_cancelled',
        color: 'danger',
        icon: 'flaticon-cancel'
    },
    6: {
        label: 'label_awaiting_payment',
        color: 'info',
        icon: 'flaticon2-reload'
    },
    7: {
        label: 'label_reserved',
        color: 'warning',
        icon: 'flaticon-menu-button'
    },
    8: {
        label: 'label_return_requested',
        color: 'metal',
        icon: 'flaticon2-time'
    },
    9: {
        label: 'label_return_accepted',
        color: 'metal',
        icon: 'flaticon2-reply'
    },
    12: {
        label: 'label_quality_check',
        color: 'metal',
        icon: 'flaticon-list'
    },
    13: {
        label: 'label_pending_delivery',
        color: 'secondary',
        icon: 'flaticon2-delivery-truck'
    },
    14: {
        label: 'label_processing',
        color: 'info',
        icon: 'flaticon-rocket'
    }
};
export const INTERFACE_LANGS = [
    { code: 'SV_SE', label: 'Svenska', id: 752 },
    { code: 'DA_DK', label: 'Dansk', id: 208 },
    { code: 'DE_DE', label: 'Deutch', id: 276 },
    { code: 'EN_US', label: 'English', id: 840 },
    { code: 'NO_NO', label: 'Norsk', id: 578 }
];
export const COUNTRY_CURRENCIES = [
    { symbol: 'SEK', code: 'SWE-SEK', label: 'Sweden (SEK)', id: 752 },
    { symbol: 'DKK', code: 'DNK-DKK', label: 'Denmark (DKK)', id: 208 },
    { symbol: 'DEM', code: 'NOR-DEM', label: 'Norway (NOK)', id: 578 },
    { symbol: 'DEM', code: 'DEU-DEM', label: 'Germany (DEM)', id: 276 }
];
export const TIME_ZONES = [
    { code: 'Europe/Stockholm', label: 'Europe / Stockholm +1' },
    { code: 'Europe / Helsinki', label: 'Europe / Helsinki +2' },
    { code: 'Atlantic / Reykjavik', label: 'Atlantic / Reykjavik -1' }
];
export const IMAGE_BASE_URL = 'https://prime.bildelsbasen.se';
export const GROUPS_LIST = [
    { id: 1, label: 'Bildelsbasen', url: 'https://www.bildelsbasen.se', ownStockUrl: 'https://cid-x.bildelsbasen.se' },
    { id: 2, label: 'System TEST', url: 'https://systemtest.bildelsbasen.se', ownStockUrl: 'https://cid-x-systemtest.bildelsbasen.se' },
    { id: 3, label: 'Varaosahaku', url: 'https://www.varaosahaku.fi', ownStockUrl: 'https://cid-x.varaosahaku.fi' },
    { id: 4, label: 'Autocirc', url: 'https://parts.autocirc.com', ownStockUrl: 'https://cid-x.parts.autocirc.com' },
    { id: 5, label: 'Vefsalan', url: 'https://vefsalan.bildelsbasen.se', ownStockUrl: 'https://cid-x-vefsalan.bildelsbasen.se' }
];
export const GROUP_URL = {
    BDB: GROUPS_LIST[0],
    SYSTEM_TEST: GROUPS_LIST[1],
    VOH: GROUPS_LIST[2],
    AUTOCIRC: GROUPS_LIST[3],
    VEFSALAN: GROUPS_LIST[4]
};