export const API_URL = {
    LOGIN_URL: '/api/django/v2/account/login/authenticate',
    RESET_PWD_URL: '/api/django/v2/account/password/reset',
    RECOVER_PWD_URL: '/api/django/v2/account/password/recover',
    RESET_PWD_TOKEN_VERIFY_URL: '/api/django/v2/account/token/verify',
    ROLE_LIST: '/api/django/v2/account/user/lrole',
    ROLE_PERMISSIONS: '/api/django/v2/account/user/lrolepermission',
    API_ENDPOINTS_URL: '/api/django/v2/account/user/lrolepermission/list_endpoint',
    EDIT_ROLE_PERMISSIONS_URL: '/rest/account/rpc/action',
    USER_PROFILE: '/api/django/v2/account/profile',
    NETS_ON_BOARDING_URL: '/rest/account/crud_nets_onboarding',
    PUSH_NOTIFICATION_URL: '/rest/account/crud_user_notify',
    ONBOARDING_PROGRESS_URL: '/rest/account/rpc/rsp_onboarding_progress',
    ACCOUNT_COMPANY_STAND_TEXT: '/rest/account/crud_company_text',
    CONFIRM_USER_AUTH: '/rest/account/rpc/rsp_login_confirm',
    AUTHOR_RSP: '/rest/account/rpc/rsp_author',
    ORDERS_LIST: '/rest/order/view_order_',
    COUNTRIES_LIST: '/api/django/v2/register/logistic/country',
    ORDER_ACTION: '/rest/order/rpc/action',
    ORDER_HISTORY_LOG: '/rest/order/view_order_log',
    AUTHORING_LIST: '/rest/language/',
    AUTHORING_SET_LANGUAGE: '/rest/language/rpc/set_language',
    AUTHORING_DELETE_LANGUAGE: '/rest/language/rpc/del_language',
    AUTHORING_GENE_JSON: '/api/django/v2/language/generate',
    IMPORT_V2_VEHICLE: '/api/django/v2/import/vehicle',
    IMPORT_V2_PDF: '/rest/import.v2/pdf',
    IMAGE_UPLOAD_URL: '/api/django/v2/import/upload',
    PART_BRAND_NAME_URL: '/api/django/v2/import/toolbox/brand_name',
    REGNO_SEARCH: '/rest/inventory/rpc/fn_tbl_regno',
    VEHICLE_MAKES_LIST: '/rest/inventory/tree_vehicle_make',
    VEHICLE_MODELS_LIST: '/rest/inventory/tree_vehicle_model',
    VEHICLE_MODEL_GROUP_LIST: '/rest/inventory/tree_vehicle_model_group',
    VEHICLE_MODEL_CODE: '/rest/inventory/tree_vehicle_model_code',
    VEHICLES_LIST: '/rest/inventory/vehicle_subject',
    PART_OEM_SEARCH_URL: '/rest/inventory/rpc/fn_tbl_create_part',
    PART_TREE_CATEGORY: '/rest/inventory/tree_part_category',
    PART_TREE_CATERGORY_ARTICLE: '/rest/inventory/tree_part_category_article',
    PART_TREE_CODE: '/rest/inventory/tree_part_code',
    VEHICLE_PARTS_LIST: '/rest/inventory/part_subject',
    BILVISION_DEMONTERA_URL: '/rest/inventory/bilvision_demontera',
    BILVISION_DEMONTERA_CODE_PC_URL: '/rest/inventory/bilvision_demontera_code_pc',
    BILVISION_DEMONTERA_VEHICLE: '/rest/inventory/bilvision_demontera_vehicle',
    ARCHIVED_PARTS_LIST: '/rest/inventory_archive/part_subject',
    ARCHIVED_PART_VEHICLE: '/rest/inventory_archive/vehicle_subject',
    DEV_JOKES: '/api/django/v2/account/dev_joke',
    ADMIN_COMPANY_LIST: '/api/django/v2/account/company/search/subject',
    ADMIN_COMPANY_FILTER_LIST: '/api/django/v2/account/company/search/filter',
    ADMIN_COMPANY_DETAILS_URL: '/api/django/v2/account/company/info',
    ADMIN_COMPANY_EMAIL_UPDATE_URL: '/api/django/v2/account/company/email',
    ADMIN_COMPANY_USERS_URL: '/api/django/v2/account/user/role',
    ADMIN_CHANGE_LOGS: '/api/django/v2/account/changelog',
    CUSTOMER_FEEDBACK_URL: '/api/django/v2/intranet/order/list_remark',
    ADMIN_COMPANY_BILLING_USE_URL: '/api/django/v2/account/setting/invoice_transactions',
    INVOICE_TRANSACTION_TYPES_URL: '/api/django/v2/account/setting/invoice_transaction/types',
    DELETE_COMPANY_BILLING_USE_URL: '/api/django/v2/account/setting/invoice_transaction',
    PUBLISH_COMPANY_URL: '/api/django/v2/account/company/memcache/clear',
    COMPANY_STATUS_BIT_URL: '/api/django/v2/account/company/batch/status',
    COMPANY_BRANCH_BIT_URL: '/api/django/v2/account/company/batch/branch',
    COMPANY_GROUP_BIT_URL: '/api/django/v2/account/company/batch/group',
    UPDATE_COMPANY_ADDRESS_URL: '/api/django/v2/account/company/address',
    COMPANY_PHONE_URL: '/api/django/v2/account/company/phone',
    COMPANY_REVIEW_URL: '/api/django/v2/account/company/review',
    COMPANY_SETTINGS_URL: '/api/django/v2/account/company/setting',
    COMPANY_STATS_URL: '/api/django/v2/account/company/analytics/prime',
    VALIDATE_COMPANY_URL: '/api/django/v2/register/address/company',
    AVAILABLE_SYSTEM_PROVIDER_URL: '/api/django/v2/account/system_provider',
    SYSTEM_PROVIDER_URL: '/api/django/v2/account/company_system_provider',
    COMPANY_ALL_REVIEWS_URL: '/api/django/v2/account/company/failed_survey',
    USERS_URL: '/api/django/v2/account/user/search/subject',
    CHANGE_PWD: '/',
    QUERIES_LIST: '/'
};
